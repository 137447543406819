import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import './ContentSlider.css'

import axios from 'axios';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import StarRateIcon from '@material-ui/icons/StarRate';
import DefaultPersonPlaceholder from '../img/default-person-placeholder.jpg'


export default function PersonContentSlider(props) {
    // Llamada a API para obtener items
    let imageBaseURL = 'https://image.tmdb.org/t/p/w200/'

    const [sliderCount, setSliderCount] = useState(0);

    function scrollToRight() {
        let thisSlider = document.getElementById(props.title);
        setSliderCount(sliderCount + 600);
        thisSlider.scroll(sliderCount, 0)
    }

    function scrollToLeft() {
        let thisSlider = document.getElementById(props.title);
        setSliderCount(sliderCount - 600);
        thisSlider.scroll(sliderCount, 0)
    }

    // Navegacion al hacer click en cada Pelicula/Serie
    const history = useHistory();
    function UrlToVisit(id) { 
        let newUrl = '/movie/' + id;
        history.push(newUrl)
        history.push('/temp');
        history.goBack();
        //window.location.reload()
    }

    // Function para redondear el valor de las resenas a un solo decimal
    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    // Ordenar fecha en DD/MM/AAAA 
    let convertDateFormat = (releaseDate) => releaseDate.split('-').reverse().join('/')

    console.log(props.responseData)
    console.log(props.responseData)
    console.log(props.responseData)
    console.log(props.responseData)
    console.log(props.responseData)

    return (
        <div className='default-padding' style={{backgroundColor: props.backgroundColor}}>
            <h2 className='content-slider__category-title' style={{color: props.textColor}}>{props.title}</h2>
            <div className={`content-slider ${props.scrollStyle}`} id={props.title}>
                <ArrowBackIosIcon className='content-slider__previous-arrow' onClick={scrollToLeft} style={{display: 'none'}} />
                <ArrowForwardIosIcon className='content-slider__next-arrow' onClick={scrollToRight} style={{display: 'none'}} />
                <div className='content-slider__items-container container-slider__items-container-border-fade'>

                    {props.responseData !== undefined ? props.responseData.cast.length > 0? props.responseData.cast.slice(0, 12).map((movie) => (
                        <div className='content-slider__item' key={movie.id}>
                            <img src={movie.poster_path !== null? imageBaseURL + movie.poster_path : DefaultPersonPlaceholder} alt={movie.title} onClick={() => UrlToVisit(movie.id)} loading="lazy"/>
                            <div className='content-slider__item-text'>
                                <span className='content-slider__item-rating' style={movie.vote_average >= 7? {backgroundColor: '#008000'} : movie.vote_average >= 5? {backgroundColor: '#FFA500'} : movie.vote_average > 0? {backgroundColor: '#FF0000'} : {}}><StarRateIcon />{round(movie.vote_average, 1)}</span>
                                <h3 className='content-slider__item-title' style={{color: props.textColor}}>{movie.title}</h3>
                                <p className='content-slider__item-date'>{movie.release_date !== undefined? convertDateFormat(movie.release_date) : ""}</p>
                            </div>
                        </div>
                    )) : "" : "Cargando"}

                    {props.responseData !== undefined ? props.responseData.crew.length > 0? props.responseData.crew.slice(0, 12).map((movie) => (
                        <div className='content-slider__item' key={movie.id}>
                            <img src={movie.poster_path !== null? imageBaseURL + movie.poster_path : DefaultPersonPlaceholder} alt={movie.title} onClick={() => UrlToVisit(movie.id)} loading="lazy"/>
                            <div className='content-slider__item-text'>
                                <span className='content-slider__item-rating' style={movie.vote_average >= 7? {backgroundColor: '#008000'} : movie.vote_average >= 5? {backgroundColor: '#FFA500'} : movie.vote_average > 0? {backgroundColor: '#FF0000'} : {}}><StarRateIcon />{round(movie.vote_average, 1)}</span>
                                <h3 className='content-slider__item-title' style={{color: props.textColor}}>{movie.title}</h3>
                                <p className='content-slider__item-date'>{movie.release_date !== undefined? convertDateFormat(movie.release_date) : ""}</p>
                            </div>
                        </div>
                    )) : "" : "Cargando"}

                </div>
            </div>
        </div>
        
    )
}
