import React from 'react'
import './About.css'

function About() {

    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'white'

    return (
        <div className='info-page contact' id='contact'>
            <h1>Sobre CineMás</h1>
            <p>CineMás es un proyecto personal en el que básicamente trabajé por hobbie; la intención del proyecto se resume en ofrecer una WebApp ligera y rápida con la información y datos más importantes del mundo del cine. A mediano plazo me gustaría crear un blog propio que sirva de apoyo a las reseñas que se muestran en la app.</p>
            <br />
            <p>Mi objetivo será siempre ir incorporando nuevas funciones a la CineMás, hasta que algún día se pueda convertir en una referencia primordial para la web cinéfila en español. Siempre estoy atento a comentarios y a mejoras que se te puedan ocurrir, sería genial que te <a href='/contacto'>contactes conmigo</a> con tus mejores ideas ;)</p>
            <br/>
            <br/>
            <br/>
            <p>CineMás funciona gracias a la API de  <a href='https://www.themoviedb.org/' target='_blank' rel='nofollow'><img src='https://www.themoviedb.org/assets/2/v4/logos/v2/blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg' style={{height: '12px'}}/> (TheMovieDatabase)</a></p>
        </div>
    )
}

export default About
