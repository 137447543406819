import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(1),
  },
}));

export default function SimpleTooltips(props) {
  const classes = useStyles();

  return (
    <div>
      <Tooltip title={props.title} placement="right" arrow >
        <IconButton aria-label="tooltip">
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    
    </div>
  );
}