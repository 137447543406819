import React, { lazy, Suspense, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useParams } from "react-router-dom"
import axios from 'axios';
import './MovieCrewPage.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DefaultPersonPlaceholder from '../img/default-person-placeholder.jpg'
import Loader from '../utils/Loader.js'


function MovieCrewPage() {

    const { movieid } = useParams()

    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'white'

      // Llamada a API para obtener data de la pelicula
      let apiKey = '65b169cfb1134174c2796c4992ee516a'
      // La app ya devuelve el path /movie/, lo uso completo en la url para la peticion
      let apiMovieDetailsUrl = `
      https://api.themoviedb.org/3/movie/${movieid}?api_key=${apiKey}&append_to_response=credits&language=es-MX`

      let [responseData, setResponseData] = React.useState('');
      const fetchData = React.useCallback(() => {
          axios({
          "method": "GET",
          "url": apiMovieDetailsUrl
          })
          .then((response) => {
          setResponseData(response.data)
          })
          .catch((error) => {
          console.log(error)
          })
      }, [])
      React.useEffect(() => {
          fetchData();
      }, [fetchData])

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // Titulo de la pagina
    // Obtener year de la pelicula
    let releaseYear = () => responseData.release_date !== undefined? responseData.release_date.slice(0, responseData.release_date.length - 6) : "";
    document.title = `${responseData.title} (${releaseYear()}) | CineMás`

    const history = useHistory()

    // Path para obtener imagenes de actores y crew
    let castImagesPath = 'https://image.tmdb.org/t/p/w200'


    // Ruta para poster de la pelicula
    let imageBaseURL = 'https://image.tmdb.org/t/p/w500/'

    // Go back a pagina principal
    let goBack = () => history.push(`/movie/${movieid}`)

    return (
        
        <div id='movie-crew-page' className='default-padding'>
            {responseData !== undefined? 
            <div>
                <div className='movie-crew-page-header'>
                    <img src={imageBaseURL + responseData.poster_path} />
                    <div>
                        <h1>{responseData.title} ({releaseYear()})</h1>
                        <button className='movie-crew-page-header-goback' onClick={() => goBack()}><ArrowBackIcon/> Volver a Principal</button>
                    </div>
                </div>
                <div className='movie-crew-page-columns'>
                        <div className='movie-crew-page-columns-cast'>
                            <h4 className='movie-crew-page-category'>Reparto</h4>
                            {responseData.credits !== undefined ? responseData.credits.cast.length > 0? responseData.credits.cast.map((cast) => ( 
                                    <div className='movie-crew-page-columns-cast-item'>
                                        <img src={cast.profile_path !== null? castImagesPath + cast.profile_path : DefaultPersonPlaceholder} style={{cursor: 'pointer'}} loading="lazy" onClick={() => history.push('/person/' + cast.id)}/>
                                        <div className='movie-main-details-cast-slider-item-details'>
                                            <h4 style={{cursor: 'pointer'}} onClick={() => history.push('/person/' + cast.id)}>{cast.name}</h4>
                                            <p>{cast.character}</p>
                                        </div>  
                                    </div> 
                                )) : "No hay resultados." : "Cargando"}
                        </div>
                        <div className='movie-crew-page-columns-cast'>
                            <h4 className='movie-crew-page-category'>Equipo de Trabajo</h4>
                            {responseData.credits !== undefined ? responseData.credits.crew.length > 0? responseData.credits.crew.map((cast) => ( 
                                <div className='movie-crew-page-columns-cast-item'>
                                    <img src={cast.profile_path !== null? castImagesPath + cast.profile_path : DefaultPersonPlaceholder} loading="lazy" style={{cursor: 'pointer'}} onClick={() => history.push('/person/' + cast.id)}/>
                                    <div className='movie-main-details-cast-slider-item-details'>
                                        <h4 onClick={() => history.push('/person/' + cast.id)} style={{cursor: 'pointer'}}>{cast.name}</h4>
                                        <p>{cast.job}</p>
                                    </div>  
                                </div> 
                            )) : "No hay resultados." : "Cargando"}
                        </div>
                    <div>

                    </div>
                </div>
                
            </div> : <p>No hemos podido encontrar el equipo de trabajo de esta película. Intenta usando nuestro buscador.</p>
        }
            
        </div>
    )
}

export default MovieCrewPage

