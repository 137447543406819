import React, { useState } from 'react'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './SearchMovieField.css'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DefaultPersonPlaceholder from '../img/default-person-placeholder.jpg'

function SearchMovieHeroField() {

    const history = useHistory();

    const [queryText, setQueryText] = useState("");
  
    const handleSubmit = (evt) => {
      evt.preventDefault();
      history.push(`/search?q=${queryText}`)
      setQueryText('')
    }

    // Llamada a API para obtener lista de peliculas
    let apiKey = '65b169cfb1134174c2796c4992ee516a'
    // La app ya devuelve el path /movie/, lo uso completo en la url para la peticion
    // let searchText = () => document.getElementById('search-movie-field-input')? document.getElementById('search-movie-field-input').value : ""


    let [responseData, setResponseData] = React.useState('');
    const fetchData = () => {
        //alert(queryText)
        let apiMovieDetailsUrl = `
        https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&query=${queryText}&language=es-MX`

        axios({
        "method": "GET",
        "url": apiMovieDetailsUrl
        })
        .then((response) => {
        setResponseData(response.data)
        })
        .catch((error) => {
        console.log(error)
        })
    }
    React.useEffect(() => {
        fetchData()
    }, [queryText])


    // Navegacion al hacer click en cada Pelicula/Serie
    
    function UrlToVisit(id, mediaType) { 
        let mediaTypeUrl = mediaType === 'movie'? '/movie/' : mediaType === 'person'? '/person/' : mediaType === 'tv'? '/tv/' : ""
        let newUrl = mediaTypeUrl + id;
        history.push(newUrl);
        setQueryText('')
        window.location.reload()
    }

    // Path para obtener imagenes de actores y crew
    let imagesPath = 'https://image.tmdb.org/t/p/w200'

    // Determinar Path de la imagen
    let determineImagePath = (path) => path !== undefined? path !== null? imagesPath + path : DefaultPersonPlaceholder : DefaultPersonPlaceholder

    // let showResultsDiv = () => document.getElementById('search-movie-field-div-results') !== null? document.getElementById('search-movie-field-div-results').style.display = 'block' : ''

    


    return (
        <div id='search-movie-field' >
            <form id='hero-searchbar-searchbar' onSubmit={handleSubmit}>
                                
                <input type='search' autoComplete="off" placeholder={document.documentElement.clientWidth > 858? 'Prueba buscar una Pelicula, Serie, Persona...' : 'Buscar...' } id='search-movie-field-input' onChange={e => setQueryText(e.target.value)} className='hero-searchbar-searchbar' /*onFocus={(e) => showResultsDiv()} */ ></input>
                <button className='hero-searchbar-button'>Buscar</button>
            </form>
            {queryText.length > 0? 
            
                <div className='search-movie-field-div-results' id='search-movie-field-div-results'> 
                    
                        {responseData.results !== undefined? responseData.results.slice(0, 4).map((result) => (
                            <div className='search-movie-field-div-results-item' onClick={() => UrlToVisit(result.id, result.media_type)}>
                                <img src={determineImagePath(result.poster_path || result.profile_path) } />
                                <div>
                                    <p>{result.title || result.name}</p>
                                    <p className='search-movie-field-mediatype'>{result.media_type === 'movie'? 'Película' : result.media_type === 'tv'? 'Serie' : result.media_type === 'person'? 'Persona' : ""}</p>
                                </div>
                            </div>
                            
                        )) : ""}
                    
                </div> : ""
        }
        </div>
    )
}

export default SearchMovieHeroField
