import React from 'react'
import './Header.css'
import { useHistory } from "react-router-dom";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import SearchMovieField from '../utils/SearchMovieField';

function Header() {
    
    function menuStateCheck() {
        if (document.getElementById('menu-state').checked === true) {
            document.getElementById('header__center-menu').style.left = '-1px'
        }
        else {
            document.getElementById('header__center-menu').style.left = '-101%'
        }

    }

    const history = useHistory()
    function HomeButton() { 
        let home = '/';
        history.push(home)
    }
    

    
    return (
        <header className='header'>
            <div className="header__left">
                <input type='checkbox' id='menu-state' onClick={menuStateCheck} />
                <label htmlFor='menu-state' className='header__left__menu-state-icon'><MenuIcon/></label>
                <img src="https://www.rottentomatoes.com/assets/pizza-pie/images/rtlogo.9b892cff3fd.png" alt="CinemasLogo" onClick={() => HomeButton()} />
            </div>
                
            <div className="header__center">
                <ul id='header__center-menu'>
                    <li className="header__center__menu-item">
                        <a href='#'>Peliculas</a>
                    </li>
                    <li className="header__center__menu-item">
                        <a href='#'>Series</a>
                    </li>
                    <li className="header__center__menu-item">
                        <a href='#'>Personas</a>
                    </li>
                </ul>
            </div>
            <div className="header__right">
                <SearchMovieField buttonClass='header__right__search-button' inputClass='header__right__search-input'/>
            </div>
        </header>
    )
}

export default Header
