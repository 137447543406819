import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import './Contact.css'
import { init } from 'emailjs-com';
init('user_id');


function Contact() {

    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'white'

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('service_rc75z6a', 'template_9ibvle7', e.target, 'user_fusz3NpMnIQ1LHa1B15Yh')
        .then((result) => {
            alert('Mensaje enviado exitosamente!');
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()


    }

    return(
        <div className='info-page contact' id='contact'>
            <h1>Contacto</h1>
            <div className='contact-two-columns-div'>
                <div>
                <p className='contact-text'>Muchas gracias por llegar hasta acá, mi nombre es Giacomo Contessi y me alegra que hayas visto mi pequeño proyecto/hobbie. Cualquier idea o comentario que puedas tener por favor no dudes en hacerlo, estoy siempre atento por aquí. ¡Muchas gracias de nuevo!</p>
                </div>
                <form onSubmit={sendEmail} id='contact-form'>
                        <input type="text" placeholder="Tu nombre" name="from_name"/>
                        <input type="email" placeholder="Correo Electrónico" name="reply_to"/>
                        <textarea  id="" cols="30" rows="8" placeholder="Mensaje" name="message"></textarea>
                        <input type="submit" value="Enviar Mensaje"></input>
                </form>

            </div>
                
        </div>
    )
}

export default Contact