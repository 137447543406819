import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

function rand() {
  return 0;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    /*minWidth: '400px', */
    width: '800px',
    maxWidth: '90vw',
    backgroundColor: 'black',
    border: '2px solid transparent',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px'
  },
}));



export default function ModalTrailerIframe(props) {


  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {/*<h2 id="simple-modal-title">Ups!</h2> */}
      
      <iframe width="100%" height="450" src={"https://www.youtube.com/embed/" + props.trailerPath[0].key} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  );


  return (
    <div>
      <button onClick={handleOpen} className='movie-main-page-header-columns-info__bookmarks-button'><PlayArrowIcon />Reproducir Trailer</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}