import React, { lazy, Suspense } from 'react'
import Loader from '../utils/Loader.js'
import HeroSearchbar from '../components/HeroSearchbar';
import { Helmet } from 'react-helmet-async';
// import ContentSlider from '../components/ContentSlider';
// import BackdropContentSlider from '../components/BackdropContentSlider';

const ContentSlider = lazy (() => import('../components/ContentSlider'))
const BackdropContentSlider = lazy (() => import('../components/BackdropContentSlider'))

function Homepage() {

    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'var(--background-color)'


    return (
        <>
            <Helmet>
                <title>CineMás | Películas, Series, Actores y todo sobre Cine</title>
                <meta name='description' content='agregar descripcion de la pagina'/>
                <link rel='canonical' href='/'/>
            </Helmet>
            <div>
                <HeroSearchbar />
                <Suspense fallback={<Loader />}>
                    <ContentSlider title='Próximos Estrenos' url='https://api.themoviedb.org/3/movie/upcoming?api_key=65b169cfb1134174c2796c4992ee516a&language=es-MX&page=1' textColor={'white'} scrollStyle={'scrollbar-horizontal'}/>
                    <div style={{paddingBottom: '1.5rem'}}>
                        <ContentSlider title='Ahora en Cines' url='https://api.themoviedb.org/3/movie/now_playing?api_key=65b169cfb1134174c2796c4992ee516a&language=es-MX&page=1'textColor={'white'} scrollStyle={'scrollbar-horizontal'}/>
                    </div>
                    <BackdropContentSlider title='Películas Trending' url='https://api.themoviedb.org/3/movie/popular?api_key=65b169cfb1134174c2796c4992ee516a&language=es-MX&page=1'textColor={'var(--background-color)'} scrollStyle={'scrollbar-horizontal-light'} backgroundColor='whitesmoke' className='footer-margin' />
                    <div className='homepage-gradient-background-wtob'>
                        <ContentSlider title='Películas en Español' url='https://api.themoviedb.org/3/discover/movie?api_key=65b169cfb1134174c2796c4992ee516a&language=es-MX&sort_by=popularity.desc&page=1&with_original_language=es'textColor={'white'} scrollStyle={'scrollbar-horizontal'}/>
                    </div>
                </Suspense>
            </div>
        </>
    )
}

export default Homepage
