import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <footer id='footer' className='footer default-padding'>
            {/*<h4 className='footer-title'>Te podría interesar:</h4> */}
            <ul className='footer-content-list'>
                <li><a href='/about' className='footer-content'>Sobre CineMás</a></li>
                <li><a href='/contacto' className='footer-content'>Contacto</a></li>
            </ul>
        </footer>
    )
}

export default Footer
