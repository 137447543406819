import React, { Suspense, lazy } from 'react'
import { usePalette } from 'react-palette';
import './MovieMainHeader.css'
import CircularProgressWithLabel from '../utils/MovieRateProgress.js'
import Tooltip from '../utils/Tooltip.js'
import ModalTrailerIframe from '../utils/ModalTrailerIframe';
import Loader from '../utils/Loader';

function MovieMainHeader(props) {

    // console.log(props.responseData.backdrop_path)

    const { data } = usePalette(`https://image.tmdb.org/t/p/w500${props.responseData.backdrop_path}`);

    // console.log(data)

      // Convertir HEX a RGB
      function hexToRgb(hex) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
    
        return r + "," + g + "," + b;
    }

        let darkVibrantColor = () => data.darkVibrant !== undefined? hexToRgb(data.darkVibrant.substring(1)) : ''
        // console.log(darkVibrantColor() + ',1.00')

      if (document.getElementById('movie-main-page') !== null) {
        document.getElementById('movie-main-page__backdrop').style.backgroundImage = `url(https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces${props.responseData.backdrop_path})`
        document.getElementById('movie-main-page__backdrop-overlay').style.backgroundImage = `linear-gradient(to right, rgba(${darkVibrantColor()}, 1.00) 150px, rgba(${darkVibrantColor()}, 0.84) 100%)`
        // div para movil
        document.getElementById('movie-main-page__backdrop-for-mobile').style.backgroundImage = `url(https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces${props.responseData.backdrop_path})`
        document.getElementById('movie-main-page__backdrop-overlay-for-mobile').style.backgroundImage = `linear-gradient(to right, rgba(${darkVibrantColor()}, 1.00), rgba(${darkVibrantColor()}, 0.00) 80%)`
        } 
        
        // Ruta para poster de la pelicula
        let imageBaseURL = 'https://image.tmdb.org/t/p/w500/'

        // Obtener year de la pelicula
        let releaseYear = (date) => date !== undefined? date.slice(0, date.length - 6) : "";

        // Ordenar fecha en DD/MM/AAAA 
        let convertDateFormat = () => props.responseData.release_date !== undefined? props.responseData.release_date.split('-').reverse().join('/') : ""

        // Obtener pais de la pelicula
        let productionCountry = () => props.responseData.production_countries !== undefined && props.responseData.production_countries.length > 0? props.responseData.production_countries[0].iso_3166_1 ?? 'N/A' : "N/A"

        // Recorrer y ordenar Generos
        let genresList = [];
        let x = 0;
        let getGenresList = () => {
            if (props.responseData.genres !== undefined) {
                for (x in props.responseData.genres) {
                let name = props.responseData.genres[x]["name"];
                let id = props.responseData.genres[x]["id"];
                genresList.push(name);
                }
            }
            else {
                console.log('nada')
            }
        return genresList.join(', ')
        }

        // Obtener duracion de la pelicula
        let runTime = () => props.responseData.runtime !== undefined? props.responseData.runtime >= 60? `${Math.floor(props.responseData.runtime / 60)}h ${(props.responseData.runtime - (60 * Math.floor(props.responseData.runtime / 60)))}m`: props.responseData.runtime + 'm' : ""

        // Obtener Valoracion (rate)
        let rateValue = () => props.responseData.vote_average !== undefined? props.responseData.vote_average * 10 : 0

    return (
        <div>
            <div id='movie-main-page__backdrop-for-mobile'>
                <div id='movie-main-page__backdrop-overlay-for-mobile'>
                    <img src={imageBaseURL + props.responseData.poster_path} alt={props.responseData.title} loading="lazy"/>
                </div>
                
            </div>
            <div className='movie-main-page__backdrop' id='movie-main-page__backdrop'>
                <div id='movie-main-page__backdrop-overlay'>
                    
                    {props.responseData !== undefined? props.responseData.title || props.responseData.name !== undefined? 
                    <div className='movie-main-page__header-columns'>
                        <div className='movie-main-page__header-columns-poster'>
                            <img src={imageBaseURL + props.responseData.poster_path} alt={props.responseData.title} loading="lazy"/>
                        </div>
                        <div className='movie-main-page__header-columns-info'>
                            <h1>{props.responseData === undefined? 'Cargando...' : props.responseData.title || props.responseData.name + ` (${releaseYear(props.responseData.release_date || props.responseData.first_air_date)})`}</h1>
                            <h3>{`${convertDateFormat()} (${productionCountry()}) - ${getGenresList()} - ${runTime()}`}</h3>
                            <div className='movie-main-page__header-columns-info__bookmarks'>
                                {props.responseData.vote_average !== undefined? <div style={{display: 'flex'}}> <CircularProgressWithLabel value={rateValue()} /> <Tooltip title='Valoraciones de Usuarios'/> </div> : ""}
                                {props.responseData.videos !== undefined? props.responseData.videos.results.length > 0 ? <ModalTrailerIframe trailerPath={props.responseData.videos.results}/> : <button className='movie-main-page-header-columns-info__bookmarks-button' style={{backgroundColor: 'grey'}}>Trailer No Disponible</button> : ""}
                            </div>
                            
                            <p><span>Sinopsis:</span><br/>{props.responseData.overview}</p>
                        </div>

                    </div> : <Loader /> : <Loader />
                    }
                    
                </div>
            </div>
            
        </div>
    )
}

export default MovieMainHeader
