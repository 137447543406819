import React, { lazy, Suspense, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import axios from 'axios';
import './MovieMainPage.css'
import Loader from '../utils/Loader.js'
import PersonHeader from '../components/PersonHeader';
import PersonContentSlider from '../components/PersonContentSlider';
import { Helmet } from 'react-helmet-async';

const PersonSidebar = lazy (() => import('../components/PersonSidebar.js'))



function PersonPage() {

    const { personid } = useParams()

    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'white'


      // Llamada a API para obtener data de la pelicula
      let apiKey = '65b169cfb1134174c2796c4992ee516a'
      // La app ya devuelve el path /movie/, lo uso completo en la url para la peticion
      let apiMovieDetailsUrl = `
      https://api.themoviedb.org/3/person/${personid}?api_key=${apiKey}&append_to_response=movie_credits&language=es`

      let [responseData, setResponseData] = React.useState('');
      const fetchData = React.useCallback(() => {
          axios({
          "method": "GET",
          "url": apiMovieDetailsUrl
          })
          .then((response) => {
          setResponseData(response.data)
          })
          .catch((error) => {
          console.log(error)
          })
      }, [])
      React.useEffect(() => {
          fetchData();
      }, [fetchData])
      

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory()

    useEffect(() => {
    return history.listen(location => {
        if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
        }

        if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
            setLocationKeys(([ _, ...keys ]) => keys)

            //fetchData()

        } else {
            setLocationKeys((keys) => [ location.key, ...keys ])

            window.location.reload()

        }
        }
    })
    }, [ locationKeys, ])

    console.log(responseData)


    return (
        
        <div className='movie-main-page' id='movie-main-page'>
            <Helmet>
                <title>{`${responseData.name} | CineMás`}</title>
                <meta name='description' content={responseData.biography || `Todo sobre ${responseData.name} en CineMás, tu website sobre el mundo del cine.`} />
                <link rel='canonical' href={`/person/${personid}`} />
            </Helmet>
            {responseData !== undefined? 
            <div>
                <PersonHeader responseData={responseData} />
                <Suspense fallback={<Loader />}>
                        <div className='movie-main-page-container'>
                            <div className='movie-main-page-container-column1'>
                                
                            <PersonContentSlider title='Participó en:' responseData={responseData.movie_credits} textColor={'var(--background-color)'} scrollStyle={'scrollbar-horizontal-light'}/>
                            </div>
                            <div className='movie-main-page-container-column2'>
                                    <PersonSidebar responseData={responseData} />
                            </div>
                        </div>
                            
                    </Suspense> 
                </div> : <Loader />}
            
        </div>
    )
}

export default PersonPage
