import React, { lazy, Suspense, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import axios from 'axios';
import './MovieMainPage.css'
import Loader from '../utils/Loader.js'
import MovieMainHeader from '../components/MovieMainHeader.js';
import { Helmet } from 'react-helmet-async';
// import MovieMainDetails from '../components/MovieMainDetails.js'
// import MovieMainWhereToWatch from '../components/MovieMainWhereToWatch.js'
// import MovieMainSidebar from '../components/MovieMainSidebar.js';
// import ContentSlider from '../components/ContentSlider';
// import MovieMainImages from '../components/MovieMainImages';

// const MovieMainHeader = lazy (() => import('../components/MovieMainHeader.js'))
const MovieMainDetails = lazy (() => import('../components/MovieMainDetails.js'))
const MovieMainWhereToWatch = lazy (() => import('../components/MovieMainWhereToWatch.js'))
const MovieMainSidebar = lazy (() => import('../components/MovieMainSidebar.js'))
const ContentSlider = lazy (() => import('../components/ContentSlider.js'))
const MovieMainMultimedia = lazy (() => import('../components/MovieMainMultimedia.js'))


function MovieMainPage() {

    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'white'

    const { movieid } = useParams()


      // Llamada a API para obtener data de la pelicula
      let apiKey = '65b169cfb1134174c2796c4992ee516a'
      // La app ya devuelve el path /movie/, lo uso completo en la url para la peticion
      let movieId = window.location.pathname
      let apiMovieDetailsUrl = `
      https://api.themoviedb.org/3${movieId}?api_key=${apiKey}&append_to_response=videos&language=es-MX`

      let [responseData, setResponseData] = React.useState('');
      const fetchData = React.useCallback(() => {
          axios({
          "method": "GET",
          "url": apiMovieDetailsUrl
          })
          .then((response) => {
          setResponseData(response.data)
          })
          .catch((error) => {
          console.log(error)
          })
      }, [])
      React.useEffect(() => {
          fetchData();
      }, [fetchData])
      

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // Titulo de la pagina
    // Obtener year de la pelicula
    let releaseYear = (date) => date !== undefined? date !== null? date !== ""? date.slice(0, date.length - 6) : "" : "" : "";
    // document.title = `${responseData.title || responseData.name } (${releaseYear(responseData.first_air_date || responseData.release_date )}) | CineMás`

    const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory()

    useEffect(() => {
    return history.listen(location => {
        if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
        }

        if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
            setLocationKeys(([ _, ...keys ]) => keys)

            //fetchData()

        } else {
            setLocationKeys((keys) => [ location.key, ...keys ])

            window.location.reload()

        }
        }
    })
    }, [ locationKeys, ])



    return (
        <>
        <Helmet>
            <title>{`${responseData.title || responseData.name } (${releaseYear(responseData.first_air_date || responseData.release_date )}) | CineMás`}</title>
            <meta name='description' content={responseData.overview}/>
            <link rel='canonical' href={`${movieId}`}/>
        </Helmet>
        <div className='movie-main-page' id='movie-main-page'>
            {responseData !== undefined? 
            <div>
                <MovieMainHeader responseData={responseData} />
                <Suspense fallback={<Loader />}>
                        <div className='movie-main-page-container'>
                            <div className='movie-main-page-container-column1'>
                                
                                    <MovieMainDetails responseData={responseData} />
                                    <MovieMainWhereToWatch responseData={responseData} />
                            </div>
                            <div className='movie-main-page-container-column2'>
                                    <MovieMainSidebar responseData={responseData} />
                            </div>
                        </div>
                        <div style={{display: 'block'}} >
                            <MovieMainMultimedia title='Multimedia:' responseData={responseData} textColor={'var(--background-color)'} scrollStyle={'scrollbar-horizontal'}/>
                            <ContentSlider title='Más como esto:' url={`https://api.themoviedb.org/3${movieId}/similar?api_key=${apiKey}&language=es-MX`} textColor={'var(--background-color)'} scrollStyle={'scrollbar-horizontal-light'}/>
                        
                        </div>
                    </Suspense> 
                </div> : <Loader />}
            
        </div>
        </>
    )
}

export default MovieMainPage
