import React, { useEffect } from 'react'
import './HeroSearchbar.css'
import SearchMovieHeroField from '../utils/SearchMovieHeroField'

function HeroSearchbar() {

    useEffect(() => {    
        let backgroundNumber = Math.floor(Math.random() * 6);
        let thisHeroSearchbar = document.getElementById('hero-searchbar');
        
        if (backgroundNumber === 0) {
            thisHeroSearchbar.style.backgroundImage = 'url("https://fondosmil.com/fondo/55042.jpg")'
        }
        else if (backgroundNumber === 1) {
            thisHeroSearchbar.style.backgroundImage = 'url("https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces/en971MEXui9diirXlogOrPKmsEn.jpg")'
        }
        else if (backgroundNumber === 2) {
            thisHeroSearchbar.style.backgroundImage = 'url("https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces/xnqust9Li4oxfhXD5kcPi3UC8i4.jpg")'
        }

        else if (backgroundNumber === 3) {
            thisHeroSearchbar.style.backgroundImage = 'url("https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces/aknvFyJUQQoZFtmFnYzKi4vGv4J.jpg")'
        }

        else if (backgroundNumber === 4) {
            thisHeroSearchbar.style.backgroundImage = 'url("https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces/4y6kEEfdrNIUnWnmELkoc3EmgG7.jpg")'
        }

        else if (backgroundNumber === 5) {
            thisHeroSearchbar.style.backgroundImage = 'url("https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces/620hnMVLu6RSZW6a5rwO8gqpt0t.jpg")'
        }
    })

        

    return (
        <div className='hero-searchbar' id='hero-searchbar'>
           <div className='hero-searchbar-container'>
                <h2>Hola!</h2>
                <h3 className='hero-searchbar-text'>Millones de películas, programas de televisión y personas por descubrir. Explora ahora.</h3>
                <SearchMovieHeroField/>
                
           </div>
        </div>
    )
}

export default HeroSearchbar
