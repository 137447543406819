import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import '../components/ContentSlider.css'
import './SearchPage.css'

import axios from 'axios';
import StarRateIcon from '@material-ui/icons/StarRate';
import DefaultPersonPlaceholder from '../img/default-person-placeholder.jpg'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';


export default function SearchPage(props) {
    
    // Color del Background para toda la pagina
    document.getElementsByTagName("BODY")[0].style.backgroundColor = 'var(--background-color)'
    document.title = `Búsqueda | CineMás`

    // Llamada a API para obtener items
    let imageBaseURL = 'https://image.tmdb.org/t/p/w200/'

    const [defaultResponseData, setDefaultResponseData] = React.useState('');
    const fetchDefaultData = React.useCallback(() => {
        axios({
        "method": "GET",
        "url": 'https://api.themoviedb.org/3/movie/popular?api_key=65b169cfb1134174c2796c4992ee516a&language=es-MX&page=1'
        })
        .then((response) => {
        setDefaultResponseData(response.data)
        })
        .catch((error) => {
        console.log(error)
        })
    }, [])
    React.useEffect(() => {
        fetchDefaultData()
    }, [fetchDefaultData])


    // Navegacion al hacer click en cada Pelicula/Serie
    const history = useHistory();
    function UrlToVisit(id) {
        let newUrl = props.url.includes("tv")? '/tv/' + id :  '/movie/' + id 
        history.push(newUrl)
        history.push('/temp');
        history.goBack();
        //window.location.reload()
    }

    // Function para redondear el valor de las resenas a un solo decimal
    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    // Ordenar fecha en DD/MM/AAAA 
    let convertDateFormat = (releaseDate) => releaseDate =!! undefined? releaseDate.split('-').reverse().join('/') : ""



    let paramsQuery = new URLSearchParams(useLocation().search)
    let queryFromParams = paramsQuery.get('q')



    // SearchBar
    const [queryText, setQueryText] = useState( queryFromParams || "");

    console.log(history)

  
    const handleSubmit = (evt) => {
      evt.preventDefault();
      //alert(`Submitting Name ${queryText}`)
    }

    // Llamada a API para obtener lista de peliculas
    let apiKey = '65b169cfb1134174c2796c4992ee516a'
    // La app ya devuelve el path /movie/, lo uso completo en la url para la peticion
    // let searchText = () => document.getElementById('search-movie-field-input')? document.getElementById('search-movie-field-input').value : ""


    let [responseData, setResponseData] = React.useState('');
    const fetchData = () => {
        //alert(queryText)
        let apiMovieDetailsUrl = `
        https://api.themoviedb.org/3/search/multi?api_key=${apiKey}&query=${queryText}&language=es-MX`

        history.replace('search?q=' + queryText)

        axios({
        "method": "GET",
        "url": apiMovieDetailsUrl
        })
        .then((response) => {
        setResponseData(response.data)
        })
        .catch((error) => {
        console.log(error)
        })
    }
    React.useEffect(() => {
        fetchData()
    }, [queryText])

    console.log(responseData)


    // Navegacion al hacer click en cada Pelicula/Serie
    function UrlToVisit(id, mediaType) { 
        console.log(id, mediaType)
        let mediaTypeUrl = mediaType === undefined || mediaType === 'movie'? '/movie/' : mediaType === 'person'? '/person/' : mediaType === 'tv'? '/tv/' : ""
        let newUrl = mediaTypeUrl + id;
        history.push(newUrl);
        setQueryText('')
        window.location.reload()
    }

    // Path para obtener imagenes de actores y crew
    let imagesPath = 'https://image.tmdb.org/t/p/w200'


    // Determinar Path de la imagen
    let determineImagePath = (path) => path !== undefined? path !== null? imagesPath + path : DefaultPersonPlaceholder : DefaultPersonPlaceholder



    return (
        <div className='default-padding'>
            <div className='searchpage-columns'>
                <div className='searchpage-columns-searchbar searchpage-searchbar'>
                    <div id='search-movie-field' className='searchpage-searchbar'>
                        <form id='hero-searchbar-searchbar' style={{display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center'}} onSubmit={handleSubmit}>

                            <SearchOutlinedIcon style={{color: 'white', fontSize: '1.6rem !important', marginRight: '.3rem'}}/>           
                            <input type='search' autoComplete="off" placeholder='Buscar...' id='search-movie-field-input' onChange={e => setQueryText(e.target.value)} className='hero-searchbar-searchbar' defaultValue={queryFromParams || ""}  style={{borderRadius: '8px', width: '100%'}} ></input>
                            {/*<button className='hero-searchbar-button'>Buscar</button>*/}
                        </form>
                    </div>
                </div>
                <div className='searchpage-columns-results' style={{color: 'white'}}>
                    <div style={{backgroundColor: props.backgroundColor}}>
                    <h2 className='content-slider__category-title' >Mostrando {responseData.results !== undefined? responseData.results.length : ""} resultados para <span style={{fontStyle: 'italic'}}>{queryText}</span>:</h2>
                    <div className='content-slider' id={props.title} style={{overflowX: 'inherit'}}>
                        <div className='content-slider__items-container container-slider__items-container-border-fade' style={{flexWrap: "wrap", justifyContent: 'space-between'}}>

                            {queryText.length > 0? responseData.results !== undefined ? responseData.results.length > 0? responseData.results.map((movie) => (
                                <div className='content-slider__item' style={{paddingBottom: '1.5rem'}} key={movie.id}>
                                    <img src={determineImagePath(movie.poster_path || movie.profile_path)} alt={movie.title || movie.name} onClick={() => UrlToVisit(movie.id, movie.media_type)} loading="lazy"/>
                                    <div className='content-slider__item-text'>
                                        <span className='content-slider__item-rating' style={movie.vote_average >= 7? {backgroundColor: '#008000'} : movie.vote_average >= 5? {backgroundColor: '#FFA500'} : movie.vote_average > 0? {backgroundColor: '#FF0000'} : {}}><StarRateIcon />{movie.media_type !== 'person'? round(movie.vote_average, 1) : ""}</span>
                                        <h3 className='content-slider__item-title' style={{color: props.textColor}}>{movie.title || movie.name}</h3>
                                        <p className='content-slider__item-date'>{convertDateFormat(movie.release_date || movie.first_air_date)}</p>
                                    </div>
                                </div>
                            )) : "No hay resultados :(" : "Cargando" : "" }
                            {queryText.length === 0? defaultResponseData.results !== undefined ? defaultResponseData.results.length > 0? defaultResponseData.results.map((movie) => (
                                <div className='content-slider__item' style={{paddingBottom: '1.5rem'}} key={movie.id}>
                                    <img src={determineImagePath(movie.poster_path || movie.profile_path)} alt={movie.title} onClick={() => UrlToVisit(movie.id, movie.media_type)} loading="lazy"/>
                                    <div className='content-slider__item-text'>
                                        <span className='content-slider__item-rating' style={movie.vote_average >= 7? {backgroundColor: '#008000'} : movie.vote_average >= 5? {backgroundColor: '#FFA500'} : movie.vote_average > 0? {backgroundColor: '#FF0000'} : {}}><StarRateIcon />{round(movie.vote_average, 1)}</span>
                                        <h3 className='content-slider__item-title' style={{color: props.textColor}}>{movie.title}</h3>
                                        <p className='content-slider__item-date'>{convertDateFormat(movie.release_date || movie.first_air_date)}</p>
                                    </div>
                                </div>
                            )) : "" : "" : ""}
                        </div>
                    </div>
                    </div>
                </div>



            </div>
            
        </div>
        
    )
}

