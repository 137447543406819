import './App.css';
import {Route, Switch} from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom'; 

// Pages
import Homepage from './pages/Homepage.js'
import MovieMainPage from './pages/MovieMainPage.js'
import MovieCrewPage from './pages/MovieCrewPage.js'
import PersonPage from './pages/PersonPage'
import About from './pages/About.js'
import Contact from './pages/Contact.js'

// Global Components
import Header from './components/Header.js'
import Footer from './components/Footer.js'
import SearchPage from './pages/SearchPage';





function App() {

  return (
    <Router>

    <style>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
    </style>
    
    <div className="App">
      <Header />
        {/*<BrowserRouter> */}
          <Switch>
            <Route path="/movie/:movieid/equipo" component={MovieCrewPage} />
            <Route path="/tv/:movieid/equipo" component={MovieCrewPage} />
            <Route path="/movie/:movieid/" component={MovieMainPage} />
            <Route path="/tv/:movieid/" component={MovieMainPage} />
            <Route path="/person/:personid/" component={PersonPage} />
            <Route path="/search" component={SearchPage} />
            <Route exact path='/about' component={About} />
            <Route exact path='/contacto' component={Contact} />
            <Route exact path="/" component={Homepage} />
          </Switch>
        {/* </BrowserRouter> */}
      <Footer />
    </div>
    </Router>
  );
}

export default App;
